import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { env } from "../../constants/env";
import BulbIcon from "../../icons/BulbIcon";
import BulbOnIcon from "../../icons/BulbOnIcon";
import LogoutIcon from "../../icons/LogoutIcon";
import { fetchAllAccounts } from "../../Redux/accounts-actions";
import { fetchSingleCompany } from "../../Redux/company-actions";
import { fetchSingleUser } from "../../Redux/users-actions";
import { deleteCookie, getCookie } from "../../utilities/functions";
import Assets from "../Assets/Assets";
import Categories from "../Help/Categories";
import Category from "../Help/Category";
import ContactUs from "../Help/ContactUs";
import Help from "../Help/Help";
import IssueBanner from "../issueBanner/IssueBanner";
import AddMember from "../Team/AddMember";
import EditMember from "../Team/EditMember";
import MembersTable from "../Team/MembersTable";
import Team from "../Team/Team";

import ServicesFees from "../Transactions/ServicesFees";
import Transactions from "../Transactions/Transactions";
import Footer from "./Footer/Footer";
import classes from "./Layout.module.css";
import SideBar from "./SideBar/SideBar";
import OpenTickets from "../Help/OpenTickets";
import MenuIcon from "../../icons/MenuIcon";
import Notifications from "../Notifications/Notifications";
import Summaries from "../Transactions/Summaries";
import NotificationsSlider from "../NotificationsSlider/NotificationsSlider";
import NewFeatures from "../NewFeatures/NewFeatures";
import Overview from "../Overview/Overview";
import Funds from "../Wallet/Funds";
import NewAccount from "../NewAccount/NewAccount";

const Layout = () => {
  const dispatch = useDispatch()
  const [showLogout, setShowLogout] = useState(false);
  const [issueBanner, setIssueBanner] = useState(false);
  const [banner, setBanner] = useState(false);
  const [newFeatures, setNewFeatures] = useState([])
  // const [companyName, setCompanyName] = useState("")

  const singleUser = useSelector(state => state.users.user)


  const [currentTheme, setCurrentTheme] = useState("light");



  const userId = getCookie('userId')
  const companyId = getCookie('companyId')

  document.addEventListener("click", (evt) => {
    const flyoutEl = document.getElementById("show-logout-card");
    let targetEl = evt.target; // clicked element      
    do {
      if (targetEl == flyoutEl) {
        // This is a click inside, does nothing, just return.
        return;
      }
      // Go up the DOM
      targetEl = targetEl.parentNode;
    } while (targetEl);
    // This is a click outside.      
    setShowLogout(false)
  });

  useEffect(() => {
    dispatch(fetchSingleUser(userId))
    dispatch(fetchAllAccounts(companyId))
  }, [companyId])


  useEffect(() => {
    if (singleUser?.isAdmin) {
      dispatch(fetchSingleCompany(companyId))
    } else {
      dispatch(fetchSingleCompany(singleUser?.company))
    }
  }, [singleUser])


  const handleThemeChange = (theme) => {
    if (theme === "dark") {
      localStorage.setItem("theme", "dark");
      setCurrentTheme("dark");
      return;
    } else if (theme === "light") {
      localStorage.setItem("theme", "light");
      setCurrentTheme("light");
    } else if (theme === "auto") {
      localStorage.setItem("theme", "auto");
      setCurrentTheme("auto");
    }
  };

  useEffect(() => {
    const preference = localStorage.getItem("theme");
    if (preference === null) {
      localStorage.setItem("theme", "light");
      return;
    }
    setCurrentTheme(preference);
  }, []);

  useEffect(() => {
    getFeatures(userId)
  }, [userId])

  useEffect(() => {
    const body = document.body;

    if (currentTheme === "light") {
      body.classList.remove("dark-mode");
      // dispatch(menuActions.themeColorMode({ themeColor: "light" }));
    } else if (currentTheme === "dark") {
      // dispatch(menuActions.themeColorMode({ themeColor: "dark" }));
      body.classList.add("dark-mode");
    } else if (currentTheme === "auto") {
      if (window.matchMedia) {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
          // Dark
          // dispatch(menuActions.themeColorMode({ themeColor: "dark" }));
          body.classList.add("dark-mode");
        } else {
          // Light
          // dispatch(menuActions.themeColorMode({ themeColor: "light" }));
          body.classList.remove("dark-mode");
        }
      } else {

      }
    }
  }, [currentTheme]);


  const getFeatures = async (id) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/feature/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setNewFeatures(result)

      console.log("NEW FEATURES DATA", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };


  const updateStatus = async (id) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/feature/update-status/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user: userId }),
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      getFeatures(userId)

      console.log("UPDATED NEW FEATURES DATA", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{
      backgroundColor: currentTheme === "dark" ? "#000a10" : ""
    }}>
      <NotificationsSlider />
      {newFeatures.length > 0 && newFeatures.map((item, i) => (<NewFeatures key={i} icon={item.icon} title={item.title} subTitle={item.subTitle} description={item.description} themeColor={currentTheme === 'light' ? 'black' : 'white'} onClick={() => updateStatus(item._id)} />))}
      {banner && <IssueBanner onClick={() => setBanner(false)} />}
      <div className={`${banner ? classes.addPadding : undefined} row`}>
        <div className={`col-2 px-0 ${classes.menu}`}>
          <div
            className={`d-flex align-items-center  ${classes.logoWrapper} `}
          >
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/prime-bridge-logo-${currentTheme === 'dark' ? 'dark' : 'dashboard'}.svg`}
              alt=""
            />
            <h3>Prime Bridge</h3>
          </div>
          <SideBar handleThemeChange={handleThemeChange} showLogout={showLogout} setShowLogout={setShowLogout} isBannerOn={issueBanner} themeMode={currentTheme} singleUser={singleUser} />

        </div>

        <div className={`col-10 ${classes.content}`}>

          <div className={classes.header}>
          </div>
          <Routes>
            <Route path="/" element={<Assets themeMode={currentTheme} />} />
            <Route path="/overview" element={<Overview themeMode={currentTheme} />} />
            <Route path="/transactions" element={<Transactions themeMode={currentTheme} />}>
              <Route path="/transactions/funds" element={<Funds />} />
              <Route path="/transactions/services-fees" element={<ServicesFees />} />
              <Route path="/transactions/summaries" element={<Summaries />} />
            </Route>
            <Route path="/team" element={<Team />} >
              <Route path="/team" element={<MembersTable />} />
              <Route path="/team/add-member" element={<AddMember />} />
              <Route path="/team/edit-member/:id" element={<EditMember />} />
            </Route>
            <Route path="/new-account" element={<NewAccount />} />
            <Route path="/help" element={<Help themeMode={currentTheme} />}>
              <Route path="/help/contact-us" element={<ContactUs />} />
              <Route path="/help/open-tickets" element={<OpenTickets />} />
              <Route path="/help/categories" element={<Categories />}>
                <Route path="/help/categories/category/:id" element={<Category />} />
              </Route>
            </Route>
            <Route path="/notifications" element={<Notifications themeMode={currentTheme} />} />
          </Routes>
        </div>

      </div>
      <Footer />
    </div>
  );
};
export default Layout;
